


















import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import PercentagePie from './percentage-pie.vue';
import DemographicComorbiditiesValue from './demographic-comorbidities-value.vue';
import CompareLine from './compare-line.vue';

@Component({
    components: {
        PercentagePie,
        CompareLine,
        DemographicComorbiditiesValue,
    },
})
export default class DemographicComorbiditiesRow extends Vue {
    @Prop() private prevalancesMap!: { [year: number]: { [demographic: string]: { [comorbidity: string]: number } }};

    @Prop() private comorbidity!: string;

    @Prop() private selectedDemographics!: Array<string>;

    @Prop() private selectedYear!: number;

    @Prop() private tableWidth!: number;

    getPrevalance (comorbidity: string, demographic: string): number {
        return this.prevalancesMap[this.selectedYear]?.[demographic]?.[comorbidity];
    }

    get cellWidth () {
        return `${(this.tableWidth - 140) / this.selectedDemographics.length}px`;
    }

    getComorbidityCircleStyle (comorbidity: string, demographic: string) {
        const percent = this.getPrevalance(comorbidity, demographic);
        return {
            width: `${50 * percent}px`,
            height: `${50 * percent}px`,
        };
    }

    get t2dmValue () {
        return this.getPrevalance(this.comorbidity, 'T2DMPopulation');
    }

    selectedComorbidityChanged () {
        this.$emit('selectedComorbidityChanged', this.comorbidity);
    }
}
