












import { Chart, PointerEventObject, TooltipFormatterContextObject } from 'highcharts';

import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class HcruOverTime extends Vue {
    @Prop() private hcruMap!: { [year: string ]: { [stratification: string]: { [ demographic: string ]: { [careType: string]: { [key: string]: number }}}}};

    @Prop() private selectedStratification!: string;

    @Prop() private hcruType!: string;

    selectedYear = '2019'

    @Watch('hcruType', { immediate: true })
    hcruTypeChanged () {
        if (this.hcruType === 'diagnosis') this.selectedYear = '0';
        else this.selectedYear = '2019';
        this.$emit('yearChanged', this.selectedYear);
        this.$nextTick(() => {
            this.selectYear();
        });
    }

    get chartData () {
        if (!this.hcruMap) return [];
        const data = Object.keys(Object.values(this.hcruMap)[0][this.selectedStratification]).map((demographic) => ({
            name: demographic,
            data: Object.values(this.categories).map((year: string) => this.hcruMap[year][this.selectedStratification][demographic].Total.CostPerPatient),
        }));
        return [...data, ...this.t2dmPopulationData];
    }

    get t2dmPopulationData () {
        if (!this.hcruMap) return [];
        return ['T2DM Population'].map((demographic) => ({
            name: demographic,
            data: Object.values(this.categories).map((year: string) => this.hcruMap[year]['T2DM Population'][demographic].Total.CostPerPatient),
        }));
    }

    get categories () {
        if (!this.hcruMap) return [];
        return Object.keys(this.hcruMap);
    }

    hcruTypeToggled () {
        this.$emit('hcruTypeChanged', this.hcruType === 'diagnosis' ? this.hcruType = 'years' : this.hcruType = 'diagnosis');
    }

    seriesToggled (data: { name: string; visible: boolean }) {
        const series = this.chart.series.find((a) => a.name === data.name);
        if (series) series.setVisible(data.visible);
    }

    get chart () {
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    mounted () {
        this.$nextTick(() => {
            this.selectYear();
            this.chart.reflow();
        });
    }

    @Watch('selectedStratification')
    selectedStratificationChanged () {
        this.$nextTick(() => {
            this.selectYear();
        });
    }

    selectYear () {
        if (this.chart && this.chart.series.length && this.chart.series[0].points) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const e: PointerEventObject = { target: this.chart.series[0].points[0] };
            this.chart.series[0].points.find((a) => a.category === this.selectedYear)!
                .onMouseOver(e);
        }
        this.chart.tooltip.hide();
    }

    get chartOptions () {
        const hcruType = this.hcruType;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'line',
                backgroundColor: 'transparent',
                height: window.innerHeight / 3,
            },
            xAxis: {
                categories: this.categories,
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
                crosshair: {
                    enabled: true,
                    color: 'rgba(0, 163, 199, 0.15)',
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                    formatter (a: { value: number }): string {
                        return `£${(a.value.toLocaleString())}`;
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;

                    return self.points!.reduce((s, point) => `${s}<br/><span style="color:${point.color}">\u25CF</span>
                        ${point.series.name} <b>${point.y.toLocaleString()}</b>`, `<i>${self.x} ${hcruType === 'years' ? '' : ' years since diagnosis'}</i>`);

                    // return `${self.x}<br /> ${self.series.name}: <b>${self.y.toLocaleString()}</b>`;
                },
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '0.9rem',
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: ['#00a3c7',
                '#f27174',
                '#faa743',
                '#7b9fa5',
                '#002e5f',
                '#61093f',
                '#b7d878',
                '#2bb673',
                '#7167ae',
                '#ea9fc6',
                '#e4205f',
                '#005c5f',
                '#61093f',
                '#002e5f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            plotOptions: {
                series: {
                    point: {
                        events: {
                            mouseOver: (e: { target: { category: string }}) => {
                                this.$emit('yearChanged', e.target.category);
                                this.selectedYear = e.target.category;
                            },
                            mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },

                        },
                    },
                    events: {
                        mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },
                    },
                },
            },
            series: this.chartData || [],
        };
    }
}
