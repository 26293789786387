







import ComorbidityPrevalance from '@/models/comorbidityPrevalance';
import Highcharts, { Chart, TooltipFormatterContextObject } from 'highcharts';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class ComorbiditiesCountOverTime extends Vue {
    @Prop() private prevalancesMap!: { [year: number]: { [demographic: string]: { [comorbidity: string]: number } }};

    @Prop() private prevalances!: Array<ComorbidityPrevalance>;

    @Prop() private selectedYear!: number;

    highchartsInstance = Highcharts;

    debounceTimer = 0;

    get chart () {
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    get chartData () {
        const stats: { [comorbidity: string]: { value: number; count: number } } = {};
        this.prevalances.forEach((a) => {
            if (a.comorbidity === 'Total' || a.year !== this.selectedYear) return;

            if (!stats[a.comorbidity]) stats[a.comorbidity] = { value: 0, count: 0 };
            stats[a.comorbidity].value += a.value;
            stats[a.comorbidity].count += 1;
        });
        return this.categories.map((a) => (
            {
                name: a,
                y: ((stats[a]?.value || 0) / (stats[a]?.count || 1)),
            }));
    }

    get categories () {
        return Object.keys(Object.values(this.prevalancesMap[this.selectedYear])[0]).filter((a) => a !== 'Total');
    }

    emitYear (year: string) {
        window.clearTimeout(this.debounceTimer);
        this.debounceTimer = window.setTimeout(() => {
            this.$emit('yearChanged', year);
        }, 100);
    }

    get chartOptions () {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'pie',
                height: 250,
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.categories,
                crosshair: {
                    enabled: true,
                    color: 'rgba(0, 163, 199, 0.15)',
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
            },
            tooltip: {
                enabled: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;
                    return `<i>${self.point.name}</i><br /><span style="color:${self.point.color}">\u25CF</span> ${self.series.name}: <b>${Math.round(self.y * 100) / 100}</b>`;
                },
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '0.9rem',
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                    formatter (a: { value: number }): string {
                        return `${a.value}%`;
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            colors: ['#00a3c7',
                '#f27174',
                '#faa743',
                '#7b9fa5',
                '#b7d878',
                '#2bb673',
                '#7167ae',
                '#ea9fc6',
                '#e4205f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            plotOptions: {
                column: {
                    stacking: 'normal',
                    marker: {
                        enabled: false,
                    },
                    lineWidth: 0,
                    borderColor: null,
                    pointWidth: 20,
                    states: {
                        hover: {
                            opacity: 1,
                        },
                        inactive: {
                            opacity: 1,
                        },
                    },
                },
                series: {
                    point: {
                        events: {
                            mouseOver: (e: { target: { category: string }}) => {
                                this.emitYear(e.target.category);
                            },
                        },

                    },
                    events: {
                    },
                    stickyTracking: true,
                },
            },
            series: [{
                name: 'mean no. comorbidities',
                colorByPoint: true,
                data: this.chartData,
                size: '80%',
                innerSize: '60%',
            }],
        };
    }
}
