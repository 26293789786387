export default class ComorbidityPrevalance {
    year!: number;

    value!: number;

    demographic!: string;

    comorbidity!: string;

    stratification!: string;
}
