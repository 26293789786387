









import { Chart, TooltipFormatterContextObject } from 'highcharts';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class HcruGraph extends Vue {
    @Prop() private hcruMap!: { [year: string ]: { [stratification: string]: { [ demographic: string ]: { [careType: string]: { [key: string]: number }}}}};

    @Prop() private selectedStratification!: string;

    @Prop() private selectedYear!: string;

    mounted () {
        this.$nextTick(() => {
            this.chart.reflow();
        });
    }

    get chart () {
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    get chartData () {
        if (!this.hcruMap || !this.hcruMap[this.selectedYear] || !this.hcruMap[this.selectedYear][this.selectedStratification]) return [];
        const data = Object.keys(this.hcruMap[this.selectedYear][this.selectedStratification]).map((demographic) => ({
            name: demographic,
            data: Object.values(this.hcruMap[this.selectedYear][this.selectedStratification][demographic]).map((v) => v.CostPerPatient),
        }));
        return [...data, ...this.t2dmPopulationData];
    }

    get t2dmPopulationData () {
        if (!this.hcruMap) return [];
        return ['T2DM Population'].map((demographic) => ({
            name: demographic,
            data: Object.values(this.hcruMap[this.selectedYear]['T2DM Population'][demographic]).map((v) => v.CostPerPatient),
        }));
    }

    get categories () {
        if (!this.hcruMap || !this.hcruMap[this.selectedYear] || !this.hcruMap[this.selectedYear][this.selectedStratification]) return [];
        return Object.keys(Object.values(this.hcruMap[this.selectedYear][this.selectedStratification])[0]);
    }

    deltaFromAverage (demographic: string, careType: string) {
        if (demographic === 'T2DM Population') return '<span class="neutral">0%</span>';
        const val = this.hcruMap[this.selectedYear][this.selectedStratification][demographic][careType].CostPerPatient;
        const avg = this.hcruMap[this.selectedYear]['T2DM Population']['T2DM Population'][careType].CostPerPatient;
        return `<span class="${val > avg ? 'up' : 'down'}">${(Math.round((val / avg) * 10000) / 100 - 100).toLocaleString()}%</span>`;
    }

    get chartOptions () {
        const deltaFromAverage = this.deltaFromAverage;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                height: window.innerHeight / 3,
            },
            xAxis: {
                categories: this.categories,
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
            },
            tooltip: {
                useHTML: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;
                    return `<i>${self.x}</i><br /><span style="color:${self.point.color}">\u25CF</span>
                        ${self.series.name}: <b>${self.y.toLocaleString()}</b>
                        <br/>Compared to T2DM Population: ${deltaFromAverage(self.series.name, String(self.x))}`;
                },
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '0.9rem',
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                    formatter (a: { value: number }): string {
                        return `£${(a.value.toLocaleString())}`;
                    },
                },
            },
            legend: {
                enabled: true,
            },
            credits: {
                enabled: false,
            },
            colors: ['#00a3c7',
                '#f27174',
                '#faa743',
                '#7b9fa5',
                '#002e5f',
                '#61093f',
                '#b7d878',
                '#2bb673',
                '#7167ae',
                '#ea9fc6',
                '#e4205f',
                '#005c5f',
                '#61093f',
                '#002e5f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: (e: {target: { name: string; visible: boolean } }) => {
                            console.log(e);
                            this.$emit('seriesToggled', { name: e.target.name, visible: !e.target.visible });
                        },
                    },
                },

            },
            series: this.chartData || [],
        };
    }
}
