























import ComorbidityPrevalance from '@/models/comorbidityPrevalance';
import Highcharts, { Chart, TooltipFormatterContextObject } from 'highcharts';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import CombormiditiesCountPie from './combormidities-count-pie.vue';

@Component({
    components: { CombormiditiesCountPie },
})
export default class ComorbiditiesCountOverTime extends Vue {
    @Prop() private prevalancesMap!: { [year: number]: { [demographic: string]: { [comorbidity: string]: number } }};

    @Prop() private prevalances!: Array<ComorbidityPrevalance>;

    highchartsInstance = Highcharts;

    debounceTimer = 0;

    selectedYear: number | null = null;

    mounted () {
        this.$nextTick(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.chart.series[0].points[0].onMouseOver({ target: this.chart.series[0].points[0] });
            this.chart.tooltip.hide();
        });
    }

    get chart () {
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    get chartData () {
        const stats: { [comorbidity: string]: { [year: string]: { value: number; count: number } } } = {};
        this.prevalances.forEach((a) => {
            if (a.comorbidity === 'Total') return;
            if (a.comorbidity !== 'Asthma'
                && a.comorbidity !== 'Atrial Fibrillation'
                && a.comorbidity !== 'Back Pain'
                && a.comorbidity !== 'Cancer'
                && a.comorbidity !== 'Depression'
                && a.comorbidity !== 'Hypertension'
                && a.comorbidity !== 'Hypothyroidism'
                && a.comorbidity !== 'IHD AMI'
                && a.comorbidity !== 'Osteoarthritis'
                && a.comorbidity !== 'Renal Disease'
                && a.comorbidity !== 'Renal Disease CKD'
                && a.comorbidity !== 'Retinopathy'
                && a.comorbidity !== 'Stroke'
            ) return;
            if (!stats[a.comorbidity]) stats[a.comorbidity] = {};
            if (!stats[a.comorbidity][a.year]) stats[a.comorbidity][a.year] = { value: 0, count: 0 };
            stats[a.comorbidity][a.year].value += a.value;
            stats[a.comorbidity][a.year].count += 1;
        });
        return Object.keys(stats).map(((a) => ({
            name: a,
            data: Object.values(stats[a]).map((b) => b.value / b.count),
        })));
    }

    get categories () {
        return Object.keys(this.prevalancesMap);
    }

    emitYear (year: string) {
        window.clearTimeout(this.debounceTimer);
        this.debounceTimer = window.setTimeout(() => {
            this.$emit('yearChanged', year);
        }, 100);
    }

    get chartOptions () {
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                height: 250,
                backgroundColor: 'transparent',
            },
            xAxis: {
                categories: this.categories,
                crosshair: {
                    enabled: true,
                    color: 'rgba(0, 163, 199, 0.15)',
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
                title: {
                    text: 'Proportional contribution to co-morbidity burden of common co-morbidities in the WSIC T2DM population by years since diagnosis over 2000-2019',
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
            },
            tooltip: {
                enabled: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;
                    return `<i>${self.x}</i> years after diagnosis<br /><span style="color:${self.point.color}">\u25CF</span> ${self.series.name}: <b>${Math.round(self.y * 100) / 100}</b>`;
                },
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '0.9rem',
                },
            },
            yAxis: {
                title: {
                    text: 'Mean no. comorbidities',
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                    formatter (a: { value: number }): string {
                        return `${a.value}`;
                    },
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: ['#00a3c7',
                '#f27174',
                '#faa743',
                '#7b9fa5',
                '#b7d878',
                '#2bb673',
                '#7167ae',
                '#ea9fc6',
                '#e4205f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            plotOptions: {
                column: {
                    stacking: 'normal',
                    marker: {
                        enabled: false,
                    },
                    lineWidth: 0,
                    borderColor: null,
                    pointWidth: 20,
                    states: {
                        hover: {
                            opacity: 1,
                        },
                        inactive: {
                            opacity: 1,
                        },
                    },
                },
                series: {
                    point: {
                        events: {
                            mouseOver: (e: { target: { category: string }}) => {
                                this.emitYear(e.target.category);
                            },
                            click: (e: { target: { point: { category: string } }}) => {
                                this.selectedYear = Number(e.target.point.category);
                            },
                        },

                    },
                    events: {
                        mouseOut: (e: MouseEvent) => { e.preventDefault(); return false; },
                    },
                    stickyTracking: true,
                },
            },
            series: this.chartData,
        };
    }
}
