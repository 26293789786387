














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CompareLine extends Vue {
    @Prop() private percentageFrom!: number;

    @Prop() private percentageTo!: number;

    @Prop() private t2dmValue!: number;

    height = 1;

    width = 1;

    get points () {
        const xMidPoint = this.width / 2;
        const yMidPoint = this.height / 2;
        const topLeft = yMidPoint - (this.percentageFrom / 2) * this.height;
        const topRight = yMidPoint - (this.percentageTo / 2) * this.height;
        const bottomLeft = yMidPoint + (this.percentageFrom / 2) * this.height;
        const bottomRight = yMidPoint + (this.percentageTo / 2) * this.height;

        const p = `M 0 ${topLeft}
                C 0 ${topLeft}, ${xMidPoint} ${yMidPoint}, ${this.width} ${topRight}
                L ${this.width} ${bottomRight}
                C ${xMidPoint} ${yMidPoint}, 0 ${bottomLeft}, 0 ${bottomLeft}
                L 0 ${topLeft}`;
        return p;
    }

    get fillId () {
        return `id_${this.percentageFrom}${this.percentageTo}`;
    }

    get fromColour () {
        return this.getHue(this.percentageFrom);
    }

    get toColour () {
        return this.getHue(this.percentageTo);
    }

    getHue (value: number) {
        if (value > this.t2dmValue && value - this.t2dmValue > 0.1) return '#f27174';
        if (value > this.t2dmValue) return '#faa743';
        if (value < this.t2dmValue) return '#00a3c7';
        return '#8da8ad';
    }
}
