import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import DataAndMethod from '../views/DataAndMethod.vue';
import Terms from '../views/Terms.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/dataAndMethod',
        name: 'DataMethod',
        component: DataAndMethod,
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
