






import { TooltipFormatterContextObject } from 'highcharts';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class DemographicComorbiditiesLine extends Vue {
    @Prop() private prevalancesMap!: { [year: number]: { [demographic: string]: { [comorbidity: string]: number } }};

    @Prop() private selectedComorbidity!: string;

    @Prop() private selectedDemographics!: Array<string>;

    @Prop() private formatDemographic!: (name: string) => string

    get years () {
        return Object.keys(this.prevalancesMap);
    }

    get chartData () {
        const chartData: Array<{ name: string; data: Array<number> }> = [];
        this.selectedDemographics.forEach((demographic) => {
            const data: Array<number> = [];
            this.years.forEach((year) => {
                data.push(this.prevalancesMap[Number(year)][demographic][this.selectedComorbidity] * 100);
            });
            chartData.push({
                name: demographic,
                data,
            });
        });
        return chartData;
    }

    get chartOptions () {
        const formatDemographic = this.formatDemographic;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'line',
                backgroundColor: 'transparent',
                height: window.innerHeight / 3,
            },
            xAxis: {
                categories: this.years,
                title: {
                    text: 'Years since diagnosis',
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },

            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Montserrat',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Montserrat',
                    },
                    formatter (a: { value: number }): string {
                        return `${(a.value.toLocaleString())}%`;
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;

                    return self.points!.reduce((s, point) => `${s}<br/><span style="color:${point.color}">\u25CF</span> ${formatDemographic(point.series.name)}: <b>${
                        point.y.toLocaleString()}%</b>`, `<i>${self.x} years after diagnosis</i>`);

                    // return `${self.x}<br /> ${self.series.name}: <b>${self.y.toLocaleString()}</b>`;
                },
                style: {
                    fontFamily: 'Montserrat',
                    fontSize: '0.9rem',
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: ['#00a3c7',
                '#f27174',
                '#faa743',
                '#7b9fa5',
                '#002e5f',
                '#61093f',
                '#b7d878',
                '#2bb673',
                '#7167ae',
                '#ea9fc6',
                '#e4205f',
                '#005c5f',
                '#61093f',
                '#002e5f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            series: this.chartData || [],
        };
    }
}
